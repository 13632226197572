
import Vue from "vue";

export default Vue.extend({
  name: "Quote",
  props: {
    text: String,
    title: String,
    subtitle: String,
  },
});
